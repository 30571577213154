import React, { useContext } from "react"
import style from "./balance.module.css"
import Icon from "./graphics/icon"
import { FireContext } from "../context/FireState"
import moment from "moment"

const Balance = () => {
  const { transactions, filter, isLoading } = useContext(FireContext)
  let amounts = 0,
    total = 0,
    income = 0,
    expense = 0

  const transactionsFiltered = transactions.filter(
    elem => elem.text.toUpperCase() === filter
  )
  amounts = filter
    ? transactionsFiltered.map(item => item.amount)
    : transactions.map(item => item.amount)

  total = amounts.reduce((acc, item) => (acc += item), 0).toFixed(2)

  income = filter
    ? transactionsFiltered.map(item => item.amount).filter(item => item > 0)
    : transactions.map(item => item.amount).filter(item => item > 0)

  expense = filter
    ? transactionsFiltered.map(item => item.amount).filter(item => item < 0)
    : transactions.map(item => item.amount).filter(item => item < 0)

  if (income.length > 0)
    income = income.reduce((acc, item) => (acc += item)).toFixed(2)
  else income = 0

  if (expense.length > 0)
    expense = expense.reduce((acc, item) => (acc += item)).toFixed(2)
  else expense = 0

  // filter by tag, sort by biggest expense
  let topTags = []
  transactions.forEach(element => {
    // if (element.amount < 0) {
    let exists = false
    for (let i = 0; i < topTags.length; i++) {
      if (topTags[i].tag === element.text) {
        // already in topTags
        // increment amount
        exists = true
        topTags[i].amount += element.amount //Math.abs(element.amount)
        break
      }
    }
    if (!exists) {
      // does not exist yet
      // add text and amount
      topTags.push({ tag: element.text, amount: element.amount }) //Math.abs(element.amount) })
    }
    // }
  })

  let filtered = topTags.filter(elem => elem.amount < 0)

  filtered.sort((a, b) => {
    if (a.amount < b.amount) {
      return -1
    } else {
      return 1
    }
  })

  return (
    <div className={style.container}>
      <p className={style.title}>Balance</p>
      {isLoading ? (
        <h1 className={[style.loadingText, style.gradient].join(" ")}>$</h1>
      ) : (
        <h1>
          {total < 0 ? "-" : "+"}
          {Math.abs(total).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </h1>
      )}
      <div className={style.horiz}>
        <div className={style.iconWrap}>
          <Icon up={true} />
        </div>
        {isLoading ? (
          <h2 className={[style.loadingText, style.gradient].join(" ")}>$</h2>
        ) : (
          <h2 className={style.balancesubtext}>
            {Math.abs(income).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </h2>
        )}
      </div>
      <div className={style.horiz}>
        <div className={style.iconWrap}>
          <Icon up={false} />
        </div>
        {isLoading ? (
          <h2 className={[style.loadingText, style.gradient].join(" ")}>$</h2>
        ) : (
          <h2 className={style.balancesubtext}>
            {Math.abs(expense).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </h2>
        )}
      </div>
      <div className={style.tagInner}>
        {/* <p>Top Spendings</p> */}
        {/* {!!filtered[0] && (
          <div className={style.tagWrap}>
            <h3>
              <span style={{ color: "#f58f92" }}># </span>
              {filtered[0].tag}
            </h3>
            <p>
              {Math.abs(filtered[0].amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        )}
        {!!filtered[1] && (
          <div className={style.tagWrap}>
            <h3>
              <span style={{ color: "#f58f92" }}># </span>
              {filtered[1].tag}
            </h3>
            <p>
              {Math.abs(filtered[1].amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        )}
        {!!filtered[2] && (
          <div className={style.tagWrap}>
            <h3>
              <span style={{ color: "#f58f92" }}># </span>
              {filtered[2].tag}
            </h3>
            <p>
              {Math.abs(filtered[2].amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        )} */}
      </div>
    </div>
  )
}

export default Balance
