import React, { useContext, useState } from "react"
import style from "./transaction-list.module.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FireContext } from "../context/FireState"
import moment from "moment"
library.add(faTrash)

const TransactionItem = ({ transaction, selectedIndex, onSelect }) => {
  //const [selected, setSelected] = useState(false)
  const sign = transaction.amount < 0 ? "-" : "+"
  const { removeTransaction } = useContext(FireContext)

  const remove = () => {
    onSelect(-1)
    //setSelected(false)
    removeTransaction(transaction.dateCreated)
  }

  
  let selected = selectedIndex === transaction.key;
  return (
    <li
      className={style.listItemWrap}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div
        className={
          transaction.amount < 0 ? style.highlightMinus : style.highlightAdd
        }
      />
      <button
        className={[style.listItem, selected && style.selected].join(" ")}
        onClick={() => selected ? onSelect(-1) : onSelect(transaction.key)}
      >
        <div style={{ textAlign: "left" }}>
          <p>{"# " + transaction.text}</p>
          <p className={style.dateText}>
            {!!transaction.dateCreated &&
              moment
                .unix(transaction.dateCreated.seconds)
                .format("h.ma dddd, MM.DD.YY")}
          </p>
        </div>

        <p>
          {sign}
          {Math.abs(transaction.amount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      </button>
      <div
        className={
          transaction.amount < 0
            ? [style.indicatorMinus, selected && style.delete].join(" ")
            : [style.indicatorPlus, selected && style.delete].join(" ")
        }
      >
        <button
          className={[style.deleteButton, selected && style.showDelete].join(
            " "
          )}
          onClick={remove}
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      </div>
    </li>
  )
}

const LoadingItem = () => {
  return (
    <li
      className={[style.listItemWrapLoad, style.gradient].join(" ")}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div className={style.listItemLoad}>
        <div>
          <p>{"# "}</p>
        </div>
        <p>{"$ "}</p>
      </div>
    </li>
  )
}

const TransactionList = () => {
  const { transactions, filter, isLoading } = useContext(FireContext)
  const [selectedIndex, setSelectedIndex] = React.useState(-1)

  let transactionsFiltered = []
  if (
    filter
  ) {
    let totalAmount = 0
    transactionsFiltered = transactions.filter(
      elem => elem.text.toUpperCase() === filter
    )
  } else {
    transactionsFiltered = isLoading
      ? [{ key: "1" }, { key: "2" }, { key: "3" }, { key: "4" }, { key: "5" }]
      : transactions
  }

  return (
    <div className={style.container}>
      <ul className={style.list}>
        {transactionsFiltered.map(transaction =>
          isLoading ? (
            <LoadingItem key={transaction.key} />
          ) : (
            <TransactionItem
              key={transaction.key}
              transaction={transaction}
              selectedIndex={selectedIndex}
              onSelect={index => setSelectedIndex(index)}
            />
          )
        )}
        {transactionsFiltered.length == 0 && (
          <div>
            <p style={{ color: "#888", fontSize: 16 }}>No Entries</p>
          </div>
        )}
      </ul>
    </div>
  )
}

export default TransactionList
