import React, { createContext, useEffect, useState } from "react"
import {
  getTransactions,
  createTransaction,
  deleteTransaction,
} from "../services/firestore"
import { AuthContext } from "./GlobalState"
import moment from "moment"
// create context
export const FireContext = createContext()

// Provider component
export const FireProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  const [transactions, setTransactions] = useState([])
  const { currUser, userCreated } = React.useContext(AuthContext)
  const [filter, setFilter] = useState(null)

  useEffect(() => {
    console.log("FireProvider -> getTransactions: ", userCreated)
    if (!userCreated) {
      setTimeout(() => {
        getTransactions(currUser, null, transactions => {
          setTransactions(transactions)
          setIsLoading(false)
        })
      }, 2500)
    } else {
      getTransactions(currUser, null, transactions => {
        setTransactions(transactions)
        setIsLoading(false)
      })
    }
  }, [])

  function addTransaction(transaction) {
    console.log("FireProvider -> createTransaction")
    createTransaction(currUser, transaction)
  }

  function removeTransaction(dateCreated) {
    console.log("FireProvider -> deleteTransaction")
    deleteTransaction(currUser, dateCreated)
  }

  function updateFilter(filterBy) {
    setFilter(filterBy)
  }

  function updateMonth(month) {
    setIsLoading(true)
    getTransactions(currUser, month, transactions => {
      setTransactions(transactions)
      setIsLoading(false)
    })
  }

  return (
    <FireContext.Provider
      value={{
        isLoading,
        transactions,
        addTransaction,
        removeTransaction,
        filter,
        updateFilter,
        updateMonth,
      }}
    >
      {children}
    </FireContext.Provider>
  )
}
