import React, { useState, useContext } from "react"
import style from "./dashboard.module.css"
import Icon from "../graphics/icon"
import TransactionList from "../transaction-list.js"
import TagList from "../tag-list.js"
import Trends from "../trends.js"
import Create from "../create.js"
import Balance from "../balance"
import { Helmet } from "react-helmet"

const Dashboard = () => {
  return (
    <div className={style.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spent - Dashboard</title>
      </Helmet>
      <TagList />
      <div className={style.container2}>
        <div className={style.balance}>
          <Balance />
        </div>

        <div className={style.transactions}>
          
          <div className={style.transactionBar}>
            <p className={style.title}>Transactions</p>
          </div>
          <div className={style.scrollable}>
            <TransactionList />
          </div>
          
        </div>
        <div className={style.trends}>
          <p className={style.title}>Trends</p>
          <Trends />
        </div>
      </div>

      <Create />
    </div>
  )
}

export default Dashboard
