
import App from "./app.js"

export default App

// import React from "react"
// import { Link, navigate } from "gatsby"
// import { getUser, isLoggedIn, waitForUser } from "../services/auth"
// import Layout from "../components/layout"
// import Home from "../components/pages/home"

// export default () => {
//   return (
//       <Layout>
//         <Home />
//       </Layout>
//   )
// }
