import React, { useContext } from "react"
import style from "./tag-list.module.css"
import moment from "moment"
import { FireContext } from "../context/FireState"
import Settings from "./settings"

const TagItem = ({ tag }) => {
  const { updateFilter } = useContext(FireContext)

  return (
    <button
      className={style.tagTouchable}
      onClick={() => {
        if (tag.text == "ALL") {
          updateFilter(null)
        } else {
          updateFilter(tag.text.toUpperCase())
        }
      }}
    >
      <li className={tag.selected ? style.itemInnerSelected : style.itemInner}>
        <p>#{tag.text}</p>
      </li>
    </button>
  )
}

const TagLoad = () => {
  return (
    <li className={[style.itemInnerLoad, style.gradient].join(" ")}>
      <p># - - - </p>
    </li>
  )
}

const TagList = () => {
  const { transactions, isLoading, filter } = useContext(FireContext)

  let tagList = ["ALL"] // = [moment().format("MMMM").toUpperCase()]
  transactions.forEach(item => {
    if (!tagList.includes(item.text.toUpperCase())) {
      tagList.push(item.text.toUpperCase())
    }
  })

  tagList = tagList.map((item, i) => {
    return {
      id: i + "",
      selected: filter ? filter.toUpperCase() == item.toUpperCase() : true ,
      text: item,
    }
  })

  tagList = isLoading ? [{ key: "1" }, { key: "2" }, { key: "3" }] : tagList

  return (
    <div className={style.container}>
      <div className={style.edge} />
      <ul className={style.list}>
        {tagList.map(tag =>
          isLoading ? (
            <TagLoad key={tag.key} />
          ) : (
            <TagItem key={tag.id} tag={tag} />
          )
        )}
      </ul>
      <div className={style.edge} />
      <Settings />
    </div>
  )
}

export default TagList
