import React, { useState, useContext } from "react"
import style from "./settings.module.css"
import { FireContext } from "../context/FireState"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file

import { DateRange } from "react-date-range"

const Settings = () => {
  const [collapsed, setCollapsed] = useState(true)
  const { updateMonth } = useContext(FireContext)
  const [state, setState] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth()+1,0),
      key: "selection",
    },
  ])

  return (
    <div className={style.container}>
      <button onClick={() => setCollapsed(!collapsed)}>
        <p>Filter</p>
      </button>

      {!collapsed && (
        <div className={style.menu}>
          <div className={style.menuBar}>
            <p>Select a Range</p>
            <button
              onClick={() => {
                setCollapsed(true)
              }}
            >
              <p>Close</p>
            </button>
          </div>
          <DateRange
            editableDateInputs={true}
            onChange={item => {
              console.log(item)
              setState([item.selection])
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <button
            onClick={() => {
              updateMonth(state[0])
              setCollapsed(true)
            }}
          >
            <p className={style.doneAction}>DONE</p>
          </button>
          {/* <button
            style={{ width: "100%" }}
            onClick={() => {
              updateMonth(4)
              setCollapsed(true)
            }}
          >
            <p className={style.monthItem}>#May</p>
          </button>
          <button
            style={{ width: "100%" }}
            onClick={() => {
              updateMonth(3)
              setCollapsed(true)
            }}
          >
            <p className={style.monthItem}>#April</p>
          </button>
           */}
        </div>
      )}
    </div>
  )
}

export default Settings
