import React from "react"
import { navigate } from "gatsby"
import { handleLogin } from "../../services/auth"
import style from "./login.module.css"
import Image from "../graphics/image"
import { AuthContext } from "../../context/GlobalState"
import { Helmet } from "react-helmet"

const Login = () => {
  const [loading, setLoading] = React.useState(false)
  const { currUser, initUser } = React.useContext(AuthContext)

  let handGoogleSignIn = () => {
    setLoading(true)
    initUser()
  }

  let goToTerms = () => {
    navigate("/app/terms")
  }

  if (currUser) {
    console.log("Login User Found, Navigating..")
    navigate("/app/dashboard")
  }

  return (
    <div className={style.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spent - Sign In</title>
      </Helmet>
      <div className={style.inner}>
        <h3>Start using Spent today!</h3>
        <div className={style.imageWrap}>
          <Image num={2} />
        </div>

        <p>
          Get access to your personalized dashboard
        </p>

        <div>
          <p>
            By signing up with your Google Account you accept these <br/>
            <span
              style={{ color: "#fd6e8c", cursor: "pointer" }}
              className={style.termsLink}
              onClick={() => goToTerms()}
            >
              Terms and Conditions
            </span>
          </p>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <button className={style.buttonContainer} onClick={handGoogleSignIn}>
            <div className={style.buttonBackground} />
            <p>Sign In With Google</p>
          </button>
        )}
      </div>
    </div>
  )
}

export default Login
