import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import PublicRoute from "../components/publicRoute"
import Login from "../components/pages/login"
import TermsAndConditions from "../components/pages/terms"
import Dashboard from "../components/pages/dashboard"
import Home from "../components/pages/home"
import { AuthProvider } from "../context/GlobalState"

const App = () => {
  if (typeof window === 'undefined') {
    global.window = {}
  }
  return (
    <AuthProvider>
      <Layout>
        <Router style={{ height: "100%" }}>
          <PublicRoute path="/" component={Home} />
          <PublicRoute path="/app/terms" component={TermsAndConditions}/>
          <PublicRoute path="/app/login" component={Login} />
          <PrivateRoute path="/app/dashboard" component={Dashboard} />
        </Router>
      </Layout>
    </AuthProvider>
  )
}

export default App
