import React, { useContext } from "react"
import style from "./trends.module.css"
import { FireContext } from "../context/FireState"
import moment from "moment"

const LoadingItem = () => {
  return (
    <div className={style.loadingItem}>
      <p>#Tag</p>
      <p>$000.00</p>
    </div>
  )
}

const TrendItem = ({ trend, base }) => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, trend.id * 100 + 500)
  }, [])

  console.log(Math.abs((trend.amount / base) * 100) + "%")

  return (
    <div
      className={[style.trendItem, !loading && style.trendItemFull].join(" ")}
      style={
        !loading
          ? {
              width: Math.abs((trend.amount / base) * 100) + "%",
            }
          : {}
      }
    >
      <p>#{trend.text}</p>
      <span style={{display: 'flex'}}>
        <p>
          {Math.abs(trend.amount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        {/* <div className={style.edge} /> */}
      </span>
    </div>
  )
}

const Trends = () => {
  const { transactions, filter, isLoading } = useContext(FireContext)

  let trendList = [] // list of tags with total amount
  transactions.forEach(item => {
    let exists = false
    for (let trend of trendList) {
      if (trend.text.toUpperCase() === item.text.toUpperCase()) {
        // increment amount
        trend.amount += item.amount
        exists = true
        break
      }
    }
    if (!exists) {
      // add trend
      trendList.push({ text: item.text, amount: item.amount })
    }
  })

  // sort by decreasing
  trendList.sort((a, b) => {
    if (Math.abs(a.amount) < Math.abs(b.amount)) {
      return +1
    } else {
      return -1
    }
  })

  trendList = trendList.map((item, i) => {
    return {
      ...item,
      id: i + "",
    }
  })

  trendList = isLoading ? [{ key: "1" }, { key: "2" },{ key: "3" }] : trendList

  return (
    <div className={style.container}>
      <ul className={style.list}>
        {trendList.map(trend =>
          isLoading ? (
            <LoadingItem key={trend.key} />
          ) : (
            <TrendItem
              key={trend.id}
              trend={trend}
              base={trendList[0].amount}
            />
          )
        )}
        {trendList.length == 0 && (
          <div>
            <p style={{ color: "#888", fontSize: 16 }}>No Entries</p>
          </div>
        )}
      </ul>
    </div>
  )
}

export default Trends
