import React, { useState, useContext } from "react"
import style from "./create.module.css"
import { FireContext } from "../context/FireState"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons"

const Create = () => {
  const [text, setText] = useState("")
  const [suggested, setSuggested] = useState("")
  const [tags, setTags] = useState([])
  const [amount, setAmount] = useState("")
  const { addTransaction, transactions } = useContext(FireContext)
  const [showInput, setShowInput] = useState(false)

  React.useEffect(() => {
    let tagList = []
    transactions.forEach(item => {
      if (!tagList.includes(item.text)) {
        tagList.push(item.text)
      }
    })
    setTags(tagList.sort())
  }, [transactions])

  const onSubmit = i => {
    if (!!!text || !!!amount) return
    const label = text.toLowerCase()
    const newTransaction = {
      text: label,
      amount: +amount * i,
    }
    setShowInput(false)
    addTransaction(newTransaction)
    setAmount("")
    setSuggested("")
    setText("")
  }

  let getSuggestion = enteredText => {
    // compare enteredText with every tags element
    let position = tags.findIndex(tag => {
      let same = true
      for (let i = 0; i < enteredText.length; i++) {
        if (tag.length <= i) {
          same = false
          break
        }
        if (tag[i].toLowerCase() !== enteredText[i].toLowerCase()) {
          same = false
          break
        }
      }
      return same
    })
    if (position >= 0) {
      return tags[position]
    } else {
      return ""
    }
  }

  return (
    <div
      className={[style.create, !showInput && style.createCollapse].join(" ")}
    >
      <div
        className={[
          style.mobileInput,
          !showInput && style.mobileInputCollapse,
        ].join(" ")}
        onClick={() => setShowInput(!showInput)}
      >
        <p
          className={[
            style.mobileInputText,
            !showInput && style.mobileInputTextCollapse,
          ].join(" ")}
        >
          {showInput ? "Hide Input" : "Show Input"}
        </p>
        <div
          className={[style.inputArrow, showInput && style.inputArrowDown].join(
            " "
          )}
        >
          <FontAwesomeIcon icon={faArrowCircleUp} color="#8DE268" size="lg" />
        </div>
      </div>
      <label style={{ textAlign: "center", paddingRight: 10 }} htmlFor="fname">
        Label
      </label>
      <span className={style.currencyinput}>
        <p>#</p>
        <div className={style.inputTagWrap}>
          <input
            className={style.inputTagText}
            value={text}
            maxLength={30}
            onKeyDown={e => {
              // check if TAB is pressed
              if (e.key == "Tab") {
                if (suggested && text !== suggested) {
                  e.preventDefault()
                  setText(suggested)
                }
              }
            }}
            onChange={e => {
              // get best suggestion
              if (e.target.value.length > 0) {
                let mostLikely = getSuggestion(e.target.value.toLowerCase())
                setSuggested(mostLikely)
              } else {
                setSuggested("")
              }
              setText(e.target.value.toLowerCase())
            }}
          />
          <div className={style.inputTagSuggestion}>{suggested}</div>
        </div>
        {/* <input
          className={style.input}
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
        /> */}
      </span>
      <label style={{ textAlign: "center", paddingRight: 10 }} htmlFor="fname">
        Amount
      </label>
      <span className={style.currencyinput}>
        <p>$</p>
        <input
          className={style.input}
          type="text"
          value={amount}
          maxLength={7}
          onChange={e => setAmount(e.target.value)}
        />
      </span>
      <div className={style.actionWrap}>
        <button
          className={style.buttonSubtract}
          onClick={e => {
            console.log(text, amount)
            onSubmit(-1)
          }}
        >
          EXPENSE
        </button>
        <button
          className={style.buttonAdd}
          onClick={() => {
            console.log(text, amount)
            onSubmit(1)
          }}
        >
          INCOME
        </button>
      </div>
    </div>
  )
}

export default Create
