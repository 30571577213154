import React from "react"
import { navigate } from "gatsby"

import { AuthContext } from "../context/GlobalState"
import { FireProvider } from "../context/FireState"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { currUser, isLoading } = React.useContext(AuthContext)
  console.log("PrivateRoute")
  if (isLoading) {
    return (
      <div style={{ width: "100%", height: 200, textAlign: "center" }}>
        Loading...
      </div>
    )
  } else if (!!!currUser) {
    navigate("/app/login")
    return null;
  } else {
    console.log(currUser.displayName)
  }

  return (
    <FireProvider>
      <Component {...rest} />
    </FireProvider>
  )
}

export default PrivateRoute
