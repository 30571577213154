import React from "react"
import { getUser } from "../../services/auth"
import style from "./home.module.css"
import Image from "../graphics/image"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChartBar,
  faPenSquare,
  faLock,
} from "@fortawesome/free-solid-svg-icons"

const Home = () => {
  let myDivToFocus = React.createRef()

  const handleOnClick = event => {
    //.current is verification that your element has rendered
    if (myDivToFocus.current) {
      myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  }

  return (
    <div className={style.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spent</title>
      </Helmet>
      {/* Landing */}
      <div className={style.inner}>
        <div className={style.left}>
          <h4>Expose your</h4>
          <h1>Spending Habits</h1>
          <p>
            Track your spending and your income
            <br />
            With a comprehensive break down
            <br />
            Easy to use and easy to start
          </p>
          <button className={style.buttonContainer} onClick={handleOnClick}>
            <div className={style.buttonBackground} />
            <p>LEARN MORE</p>
          </button>
        </div>
        <div className={style.right}>
          <Image num={1} />
        </div>
      </div>
      {/* Features */}
      <div className={style.feature_inner} ref={myDivToFocus}>
        <div className={style.feature_title}>
          <h3>Features</h3>
          <div className={style.feature_title_bar} />
        </div>
        <div className={style.feature_grid}>
          <div className={style.feature_grid_item}>
            <FontAwesomeIcon icon={faChartBar} color="#8DE268" size="5x" />
            <p className={style.feature_grid_item_text}>
              Visualize your spending
            </p>
          </div>
          <div className={style.feature_grid_item}>
            <FontAwesomeIcon icon={faPenSquare} color="#8DE268" size="5x" />
            <p className={style.feature_grid_item_text}>
              Easy Input with suggestions
            </p>
          </div>
          <div className={style.feature_grid_item}>
            <FontAwesomeIcon icon={faLock} color="#8DE268" size="5x" />
            <p className={style.feature_grid_item_text}>
              Secure Sign In with Google
            </p>
          </div>
        </div>
      </div>
      {/* Vision */}
      <div className={style.vision_inner}>
        <div className={style.feature_title}>
          <h3>Vision</h3>
          <div className={style.feature_title_bar} />
        </div>
        <div className={style.vision_horiz}>
          <div className={style.vision_half}>
            <p>
              Tracking your spending can be the most gruesome, boring and
              confusing task. Our goal is to simplify the process and your
              relationship with your personal finances.
              <br />
              <br />
              We do not require external accounts or any personal information.
              Allowing you to focus on tracking the things you care about most.
              No more, no less.
              <br />
              <br />
              Custom categories allow you to group purchases and never having
              misclassified items. You are finally in control.
            </p>
          </div>
          <div className={style.vision_half}>
            <Image num={3} />
          </div>
        </div>
      </div>
      {/* Dashboard */}
      <div className={style.vision_inner}>
        <div className={style.feature_title}>
          <h3>Dashboard</h3>
          <div className={style.feature_title_bar} />
        </div>
        <div className={style.vision_horiz_rev}>
          <div className={style.vision_half_image}>
            <Image num={4} />
            {/* <div className={style.vision_image_bkg}/> */}
          </div>
          <div className={style.vision_half}>
            <p>
              The dashboard will be your new home, keep it open in a tab or
              quickly jump on from your phone.
              <br />
              <br />
              The trick is to make it a habit! Seeing an overview of your goals
              and spendings helps keep you in the loop.
              <br />
              <br />
              No. More. Guessing.
            </p>
          </div>
        </div>
      </div>

      <div className={style.footer_inner}>
        <p>2020 Created by Aliaksei Chumakou</p>
      </div>
    </div>
  )
}

export default Home
